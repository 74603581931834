<div class="bh-create">
    <div class="bh-create-form min-w-[19rem]">
        <div class="bh-create-title">Register</div>
        <div class="bh-form">
            <div class="bh-form-text bh-form-text-xs">
                <span class="" (click)="null">To access free video explanations or download answer key, please create an account.</span>
            </div>
            <div class="bh-form-text bh-form-text-xs bh-form-text-lightgray mt-2 mb-2">
                <span>If you have an account please</span>
                <span class="bh-form-link" (click)="actionOpenAccessMultipasport()">login</span>.
            </div>
            <div class="bh-form-input-group">
                <div class="bh-form-text bh-form-text-xs">{{'components.create-multipasport.form.first_name' | transloco}}</div>
                <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('first_name')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                    </svg>
                    <input name="first_name" type="text" placeholder="{{'placeholders.first_name' | transloco}}"  [value]="interface.card.first_name" (keyup)="actionEnterFirstName($event)" (focusout)="actionGrantFirstName()">
                </div>
                <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('first_name')">{{'components.create-multipasport.errors.first_name' | transloco}}</ng-container>&nbsp;</div>
            </div>
            <div class="bh-form-input-group">
                <div class="bh-form-text bh-form-text-xs">{{'components.create-multipasport.form.last_name' | transloco}}</div>
                <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('last_name')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                    </svg>
                    <input name="last_name" type="text" placeholder="{{'placeholders.last_name' | transloco}}"  [value]="interface.card.last_name" (keyup)="actionEnterSecondName($event)" (focusout)="actionGrantSecondName()">
                </div>
                <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('last_name')">{{'components.create-multipasport.errors.last_name' | transloco}}</ng-container>&nbsp;</div>
            </div>
            <div *ngIf="interface.tab == 'user'" class="bh-form-input-group">
                <div class="bh-form-text bh-form-text-xs">{{'components.create-multipasport.form.email' | transloco}}</div>
                <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('email')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                    </svg>
                    <input name="email" type="text" role="spinbutton" autocomplete="new-password" placeholder="{{'placeholders.email' | transloco}}" [readonly]="interface.submit" [value]="interface.card.email" (keyup)="actionEnterEmail($event)" (paste)="actionPasteEmail($event)" (focusout)="actionGrantEmail()">
                </div>
                <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('email')">{{'components.create-multipasport.errors.email' | transloco}}</ng-container>&nbsp;</div>
            </div>
            <div *ngIf="interface.tab == 'user'" class="bh-form-input-group">
                <div class="bh-form-text bh-form-text-xs">{{'components.create-multipasport.form.password' | transloco}} (minimum 8 characters in length)</div>
                <div class="bh-form-input" [class.bh-form-input-readonly]="interface.submit" [class.bh-form-input-error]="interface._cache.error_fields.includes('password')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    <input name="password" type="text" role="spinbutton" autocomplete="new-password" placeholder="{{'placeholders.password' | transloco}}" [value]="interface.card.password" (keyup)="actionEnterPassword($event)" (paste)="actionPastePassword($event)" (focusout)="actionGrantPassword()" [readonly]="interface.submit">
                </div>
                <div class="bh-form-text bh-form-text-xs bh-form-text-error"><ng-container *ngIf="interface._cache.error_fields.includes('password')">{{'components.create-multipasport.errors.password' | transloco}}</ng-container>&nbsp;</div>
            </div>
            <div class="bh-form-input-group !hidden">
                <div class="bh-form-checkbox">
                    <input id="agree" name="agree" type="checkbox" (change)="actionConfirmAccepted($event)" [disabled]="interface.submit" checked="checked">
                    <label for="agree">{{'components.create-multipasport.form.agree' | transloco}}</label>
                </div>
            </div>
            <button class="bh-btn bh-btn-sap bh-btn-lg !mt-0" [disabled]="!actionGrantStart() || interface.submit" (click)="actionIssueCard()">
                <svg *ngIf="!interface.submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                </svg>
                <svg *ngIf="interface.submit" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span *ngIf="!interface.submit && subscribe" >Subscribe For Free</span>
                <span *ngIf="!interface.submit && !subscribe" >Create Account</span>
                <span *ngIf="interface.submit" >{{'global.check' | transloco}}</span>
            </button>
            <div class="bh-form-text bh-form-text-xs bh-form-text-lightgray mt-4 !flow-root">
                <span>By clicking the Create Account button, you agree to our</span>
                <span class="bh-form-link" routerLink="/terms/"> Terms of Use</span>
                <span> and </span>
                <span class="bh-form-link" routerLink="/privacy-policy/">Privacy Policy</span>
                <span>. You will receive occasional emails from us.</span>
            </div>
        </div>
    </div>
</div>
