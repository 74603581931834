import {ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {TRANSLOCO_SCOPE, TranslocoService} from "@ngneat/transloco";
import {initUser} from "../../../_stores/user/_actions/user.actions";
import {Router} from "@angular/router";
import {AuthAllowAccess, AuthNewToken} from "../../../_stores/auth/_actions/auth.actions";
import {AuthService} from "../../../_stores/auth/_services/auth.service";
import {UserGeneratorService} from "../../../_services/user.generator.service";
import {HttpClient} from "@angular/common/http";
import {API} from "../../../_stores/api";


@Component({
  selector: 'bh-create-multipasport',
  templateUrl: './bh-create-multipasport.component.html',
  styleUrls: ['./bh-create-multipasport.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/create-multipasport', alias: 'components.create-multipasport' }}]
})
export class BhCreateMultipasportComponent implements OnInit {
  @Input() subscribe: boolean = false;
  interface: {
    tab: string,
    tabs: string[],

    submit: boolean,
    card: {
      first_name: string,
      last_name: string,
      avatar: string,


      email: string,
      password: string,
      accepted: boolean,
      access_level: string,
    }
    _cache: any,
  } = {
    tab: 'user',
    tabs: ['guest', 'user'],


    submit: false,
    card: {
      first_name: '',
      last_name: '',
      avatar: 'avatar_000.svg',


      email: null,
      password: null,
      accepted: true,
      access_level: 'user',
    },
    _cache: {
      error_fields: [],
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private authService: AuthService, private transloco: TranslocoService, private generator: UserGeneratorService,private router: Router,private http: HttpClient) {
  }

  ngOnInit(): void {
    this.actionGenerateAvatar();
  }

  actionSelectTab(tab): void {
    if(this.interface.tab == tab) {
      return;
    }
    this.interface.tab = tab;
    this.actionChangeAccessLevel(tab);
  }
  actionChangeAccessLevel(level): void {
    this.interface.card.access_level = level;
    if (this.interface.card.access_level == 'guest') {
      this.interface.card.email = null;
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
      this.interface.card.password = null;
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
    }
  }
  actionGenerateAvatar(): void {
    this.interface.card.avatar = 'https://argoprep.com/assets/images/avatars/avatar-' + (Math.floor(Math.random() * 11) + 1) + '.svg';
  }

  actionEnterFirstName(event): void {
    //console.log(event.target.value);
    this.interface.card.first_name = event.target.value;
    //console.log(this.interface.card.first_name);
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'first_name');
  }
  actionGenerateFirstName(): void {
    if(this.interface.submit) {
      return;
    }
    this.interface.card.first_name = this.generator.userName('first');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'first_name');
  }
  actionGrantFirstName(): boolean {
    let _grant = true;
    if (this.interface.card.first_name.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('first_name');
    }
    return _grant;
  }

  actionEnterSecondName(event): void {
    this.interface.card.last_name = event.target.value;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'last_name');
  }
  actionGenerateSecondName(): void {
    if(this.interface.submit) {
      return;
    }
    this.interface.card.last_name = this.generator.userName('second');
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'last_name');
  }
  actionGrantSecondName(): boolean {
    let _grant = true;
    if (this.interface.card.last_name.length < 3) {
      _grant = false;
    }
    if(!_grant) {
      this.interface._cache.error_fields.push('last_name');
    }
    return _grant;
  }

  actionEnterEmail(event): void {
    this.interface.card.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
  }
  actionPasteEmail(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.email = pastedText.toLowerCase();
      this.actionGrantEmail();
    }
    return false;
  }
  actionGrantEmail(only_check?: boolean): boolean {
    let _grant = true;
    const _email_rules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    if (!_email_rules.test(this.interface.card.email)) {
      if(!only_check) {
        this.interface._cache.error_fields.push('email');
      }
      _grant = false;
    }
    return _grant;
  }

  actionEnterPassword(event): void {
    this.interface.card.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
    this.cdr.detectChanges();
  }
  actionPastePassword(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.password = pastedText;
      this.actionGrantPassword();
    }
    return false;
  }
  actionGeneratePassword(): void {
    if(this.interface.submit) {
      return;
    }
    this.interface.card.password = this.generator.userPassword();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
  }
  actionGrantPassword(only_check?: boolean): boolean {
    let _grant = true;
    if (!this.interface.card.password) {
      _grant = false;
    }

    if (this.interface.card.password && this.interface.card.password.length < 8) {
      _grant = false;
    }
    if(!_grant) {
      if(!only_check) {
        this.interface._cache.error_fields.push('password');
      }
    }
    return _grant;
  }

  actionConfirmAccepted(event): void {
    if(this.interface.submit) {
      return;
    }
    this.interface.card.accepted = event.target.checked;
  }



  actionGrantStart(): boolean {
    let _grant = true;
    if (this.interface.card.first_name.length < 3) {
      _grant = false;
    }
    if (this.interface.card.last_name.length < 3) {
      _grant = false;
    }
    if (!this.interface.card.accepted) {
      _grant = false;
    }
    if (this.interface.card.access_level == 'user') {
      if (!this.interface.card.email) {
        _grant = false;
      }
      if (this.interface.card.email && this.interface.card.email.length < 6) {
        _grant = false;
      }
      if (!this.interface.card.password) {
        _grant = false;
      }

      if (this.interface.card.password && this.interface.card.password.length < 8) {
        _grant = false;
      }
    }
    return _grant;
  }

  actionIssueCard(): void {
    if(window.location.host === 'localhost:4200') {
      //return;
    }


    this.interface.submit = true;
    this.interface._cache.error_fields = [];


    if(this.interface.card.access_level == 'user') {
      let _user = {
        login: this.interface.card.email.toLowerCase(),
        password: this.interface.card.password,
        first_name: this.interface.card.first_name.toLowerCase().replace(/^./, (char) => char.toUpperCase()),
        last_name: this.interface.card.last_name.toLowerCase().replace(/^./, (char) => char.toUpperCase()),
        avatar: this.interface.card.avatar,
      };
      this.authService.create(_user).subscribe({
        next: _next => {
            this.authService.login(this.interface.card.email, this.interface.card.password).subscribe({
              next: _next => {
                if(_next.statusCode == 200) {

                  this.interface.submit = false;
                  this.store.dispatch(new AuthNewToken(_next.user_data.token));
                  if(sessionStorage.getItem('previousUrl')?.includes('book-video-explanation')) {
                    this.addKlaviyo(true);
                    this.router.navigate([sessionStorage.getItem('previousUrl')]).then();
                    sessionStorage.removeItem('previousUrl');
                  }
                  else {
                    this.addKlaviyo();
                    this.router.navigate(['/dashboard']).then();
                  }
                }
              },
              error: _error => {
                this.interface.submit = false;
              }
            });
        },
        error: _error => {
          this.interface.submit = false;
          if(_error.error.statusCode == 422) {
            this.interface._cache.error_fields.push('email');
          }
        }
      });
    }

    if(this.interface.card.access_level == 'guest') {
      this.store.dispatch(new initUser(this.interface.card));
      this.store.dispatch(new AuthAllowAccess());
      this.router.navigate(['/']).then();
    }
  }


  addKlaviyo(bve: boolean = false): void {

    this.http.post<any>(API.urlWithHash('klaviyo/add-to-list'), {
      listId: bve? 'VLACud' : 'WTkF7k',
      email: this.interface.card.email.toLowerCase()
    }).subscribe({
      next: (_next) => {},
      error: (_error) => {}
    });
  }


  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.actionGrantStart()) {
      this.actionIssueCard();
    }
  }


  actionOpenAccessMultipasport(): void {
    this.router.navigate(['/auth']).then();
  }
}
