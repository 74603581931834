import {
    Action,
    createSelector,
    NgxsAfterBootstrap,
    NgxsOnChanges,
    NgxsOnInit,
    NgxsSimpleChange,
    Selector,
    State,
    StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {WorksheetsModel} from "../_models/worksheets.model";
import {InitWorksheetsCategories, InitWorksheetsItems} from "../_actions/worksheets.actions";
import {WorksheetsService} from "../_services/worksheets.service";
import {TranslocoService} from "@ngneat/transloco";
import {default as _WS_SEO} from '../../../../assets/json/self/ws_texts.json';


export const _WorksheetsDefault: WorksheetsModel = {
  categories: [],
  items: [],
  menu: [],
};

@State<WorksheetsModel>({
  name: 'V04_APO_WORKSHEETS',
  defaults: _WorksheetsDefault,
})
@Injectable()
export class WorksheetsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private worksheetsService: WorksheetsService, private transloco: TranslocoService) {}

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    ctx.dispatch(new InitWorksheetsItems());
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    _WS_SEO.forEach((_item: any) => {
      this.transloco.setTranslation({['nav.' + _item.slug + '']: _item.title});
    })

    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      menu: _WS_SEO
    });

  }
  ngxsOnChanges(change: NgxsSimpleChange<WorksheetsModel>): void {}


  @Selector()
  static selectWorksheets( state: WorksheetsModel) {
    return state.items;
  }
  static selectWorksheetBySlug( _slug: string ) {
    return createSelector([ WorksheetsState.selectWorksheets ], ( _worksheets: any[]) => {
      return _worksheets.find(x => x.slug === _slug);
    });
  }
  @Selector()
  static selectCategories( state: WorksheetsModel) {
    return state.categories;
  }
  @Selector()
  static selectMenu(state: WorksheetsModel) {
    return state.menu;
  }
  @Selector()
  static selectMenuTree(state: WorksheetsModel) {
    const data = state.menu || []; // Предотвращаем ошибку, если menu undefined
    const map = new Map<number, any>();

    data.forEach(item => {
      map.set(item.id, { ...item, children: [] });
    });

    const tree: any[] = [];
    data.forEach(item => {
      if (!item.parent) {
        tree.push(map.get(item.id)); // Добавляем корневой узел
      } else {
        const parent = map.get(item.parent);
        if (parent) {
          parent.children.push(map.get(item.id)); // Добавляем в children родителя
        } else {
          console.warn(`Parent ID ${item.parent} not found for item ID ${item.id}`);
        }
      }
    });

    return tree;
  }

  static selectMenuItemBySlug( _slug: string ) {
    return createSelector([ WorksheetsState.selectMenu ], ( _ws: any[]) => {
      return _ws.find(x => x.slug === _slug);
    });
  }

  @Action(InitWorksheetsCategories)
  public initWorksheetsCategories(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    const _categories =  [
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.math.title',
        description: 'pages.worksheet-generators.categories.math.description',
        thumbnail: 'bg_wg_1.svg',
        slug: 'math'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.reading.title',
        description: 'pages.worksheet-generators.categories.reading.description',
        thumbnail: 'bg_wg_0.svg',
        slug: 'reading'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.tracing.title',
        description: 'pages.worksheet-generators.categories.tracing.description',
        thumbnail: 'bg_wg_2.svg',
        slug: 'tracing'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.logic.title',
        description: 'pages.worksheet-generators.categories.logic.description',
        thumbnail: 'bg_wg_3.svg',
        slug: 'logic'

      },
    ];
    ctx.patchState({
      ..._state,
      categories: _categories
    });
  }

  @Action(InitWorksheetsItems)
  async initWorksheetsItems(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    let _worksheets = await this.worksheetsService.getWorksheets();
    ctx.patchState({
      ..._state,
      items: _worksheets,
    });



/*    _worksheets.forEach((_worksheet) => {
      let _k = 'nav.' + _worksheet.slug + '';
      let _v = _worksheet.title;
      this.transloco.setTranslation({[_k]: _v});
    });*/
  }

}
