import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngxs/store";
import {AuthNewToken} from "../../../_stores/auth/_actions/auth.actions";
import {Router} from "@angular/router";
import {AuthService} from "../../../_stores/auth/_services/auth.service";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'bh-login',
  templateUrl: './bh-login.component.html',
  styleUrls: ['./bh-login.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/login', alias: 'components.login' }}]
})
export class BhLoginComponent implements OnInit {
  @ViewChild('password', {read: ElementRef}) password_input: ElementRef<HTMLElement>;
  @Input('_text') set _text(_text) {
    if (_text) {
      this.interface._text = _text;
    }
  }
  @Input() horizontal: boolean = false;
  @Input() checkout: boolean = false;

  interface: {
    _text: string
    submit: boolean,
    card: {
      email: string,
      password: string,
    }
    _cache: any,
  } = {
    _text: undefined,
    submit: false,
    card: {
      email: null,
      password: null,
    },
    _cache: {
      show_password: false,
      error_fields: [],
      subscribe: {
        user: undefined
      }
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router, private authService: AuthService) {
  }


  ngOnInit(): void {
    if(window.location.host == 'localhost:4200') {
      this.interface.card = {
        email: environment.adm[0],
        password: environment.adm_pass,
      }
    }
  }


  actionEnterEmail(event): void {
    this.interface.card.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
  }
  actionPasteEmail(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.email = pastedText.toLowerCase();
      this.actionGrantEmail();
    }
    return false;
  }
  actionGrantEmail(only_check?: boolean): boolean {
    let _grant = true;
    const _email_rules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    if (!_email_rules.test(this.interface.card.email)) {
      if(!only_check) {
        this.interface._cache.error_fields.push('email');
      }
      _grant = false;
    }
    return _grant;
  }

  actionEnterPassword(event): void {
    this.interface.card.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'password');
    this.cdr.detectChanges();
  }
  actionPastePassword(event): boolean {
    let clipboardData = event.clipboardData;

    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.card.password = pastedText;
      this.actionGrantPassword();
    }
    return false;
  }
  actionGrantPassword(only_check?: boolean): boolean {
    let _grant = true;
    if (!this.interface.card.password) {
      _grant = false;
    }

    if (this.interface.card.password && this.interface.card.password.length < 5) {
      _grant = false;
    }
    if(!_grant) {
      if(!only_check) {
        this.interface._cache.error_fields.push('password');
      }
    }
    return _grant;
  }
  actionToggleShowPassword(): void {
    this.interface._cache.show_password = !this.interface._cache.show_password;
    if(this.interface._cache.show_password) {
      this.password_input.nativeElement.setAttribute('type', 'text');
    } else {
      this.password_input.nativeElement.setAttribute('type', 'password');
    }

  }


  actionGrant(): boolean {
    return this.actionGrantEmail(true) && this.actionGrantPassword(true);
  }
  actionLogin() {
    this.interface._cache.error_fields = [];
    this.interface.submit = true;
    this.authService.login(this.interface.card.email, this.interface.card.password).subscribe({
      next: _next => {
        //this.interface.submit = false;
        if(_next.statusCode == 200) {
          this.store.dispatch(new AuthNewToken(_next.user_data.token));
        }
      },
      error: _error => {
        this.interface.submit = false;
        if(_error.error.statusCode == 400) {
          if(_error.error.message == "user.error.not-found-login") {
            this.interface._cache.error_fields.push('email');
          }
          if(_error.error.message == "user.error.not-correct-password") {
            this.interface._cache.error_fields.push('password');
          }
        }
      }
    });
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.actionGrant()) {
      this.actionLogin();
    }
  }


  actionOpenForgotAccess(): void {
    this.router.navigate(['/auth/forgot']).then();
  }
  actionOpenCreateMultipasport(): void {
    this.router.navigate(['/auth/create']).then();
  }

}
