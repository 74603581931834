export const environment = {
  baseUrl: 'https://argoprep.com',
  api_endpoint: '',
  production: true,
  storeDevtools: false,
  hmr: false,
  apiUrl: '',
  default_language: 'en',
  adm_pass: '321321321',
  adm: ['info@argoprep.online', 'argoprepak@gmail.com', 'nadezhdaargo@gmail.com'],
  managers: ['anayet@argoprep.com', 'vlad@argoprep.com', 'info@argoprep.online', 'argoprepak@gmail.com', 'nadezhdaargo@gmail.com']
};

