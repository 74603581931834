import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ModalModel} from "../_models/modal.model";
import {
    ModalAfterOpenAction,
    ModalCloseAction,
    ModalOpenAction,
    ModalOpenActionWithID,
    ModalOpenActionWithPlan,
    ModalOpenActionWithVideo
} from "../_actions/modal.actions";


export const _ModalDefault: ModalModel = {
  status: false,
  type: null,
  close: true,
  after_close: null,
  _id: null,
};

@State<ModalModel>({
  name: 'V04_APO_MODAL',
  defaults: _ModalDefault,
})
@Injectable()
export class ModalState implements NgxsOnInit, NgxsOnChanges {

  constructor(private store: Store) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<ModalModel>): void {}

  @Selector()
  static selectStatus(state: ModalModel) {
    return state;
  }
  @Selector()
  static selectOpenedID(state: ModalModel): string {
    return state._id;
  }
  @Selector()
  static selectOpenedPlan(state: ModalModel): string {
    return state.plan;
  }


  @Action(ModalOpenAction)
  modalOpenAction(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
    });
  }

  @Action(ModalOpenActionWithID)
  modalOpenActionWithID(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      this.store.dispatch(new ModalCloseAction());
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
      _id: type._id != undefined ? type._id : null,
    });
  }

  @Action(ModalOpenActionWithPlan)
  modalOpenActionWithPlan(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      this.store.dispatch(new ModalCloseAction());
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
      plan: type.plan != undefined ? type.plan : null,
    });
  }
  @Action(ModalOpenActionWithVideo)
  modalOpenActionWithVideo(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      this.store.dispatch(new ModalCloseAction());
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
      video: type.video != undefined ? type.video : null,
    });
  }

  @Action(ModalAfterOpenAction)
  modalAfterOpenAction(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      this.store.dispatch(new ModalCloseAction());
      return;
    }
    ctx.patchState({
      ...state,
      after_close: type.data,
      close: type.close != undefined ? type.close : true,
      plan: type.plan != undefined ? type.plan : null,
    });
  }


  @Action(ModalCloseAction)
  modalCloseAction(ctx: StateContext<ModalModel>, type: { after: any; }) {
    const state = ctx.getState();
    if(type.after && state.after_close) {
      ctx.setState(_ModalDefault);
      if(state.after_close == 'get_premium') {
        ctx.dispatch(new ModalOpenActionWithPlan(state.after_close, state.plan));
      }
    } else {
      ctx.setState(_ModalDefault);
    }
  }

}
