import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {TipsModel} from "../_models/tips.model";
import {TipsCloseAction, TipsOpenAction} from "../_actions/tips.actions";


export const _TipsDefault: TipsModel = {
  show: false,
  options: {
    type: undefined,
    count: 0,
    image: undefined
  }
};

@State<TipsModel>({
  name: 'V04_APO_TIPS',
  defaults: _TipsDefault,
})
@Injectable()
export class TipsState implements NgxsOnInit, NgxsOnChanges {

  constructor() {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<TipsModel>): void {}

  @Selector()
  static selectStatus( state: TipsModel ) {
    return state;
  }


  @Action(TipsOpenAction)
  public tipsOpenAction(ctx: StateContext<TipsModel>, tip: any) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      show: true,
      options: {
        ...tip.options,
      }
    });
  }

  @Action(TipsCloseAction)
  public tipsCloseAction(ctx: StateContext<TipsModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      show: false,
      options: {
        ..._TipsDefault.options,
      }
    });
  }

}
