import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Inject, Injectable, Optional, PLATFORM_ID} from "@angular/core";
import {
  AuthAllowAccess,
  AuthAllowPinAccess,
  AuthChangeAccessWithPin,
  AuthDisallowAccess,
  AuthDisallowPinAccess,
  AuthListing,
  AuthRemindPin,
  AuthStatus,
  AuthSwitchUserToChild,
  AuthUpdateToken,
} from "../_actions/auth.actions";
import {AuthService} from "../_services/auth.service";

import {AuthModel} from "../_models/auth.model";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {Request} from 'express';
import {UserState} from "../../user/_state/user.state";
import {Router} from "@angular/router";
import {SetSoundAction} from "../../settings/_actions/settings.actions";
import {SwitchUserToChild, SwitchUserToDefault} from "../../user/_actions/user.actions";
import {UserModel} from "../../user/_models/user.model";

export const _AuthDefault: AuthModel = {
  access: false,
  status: false,
  token: null,
  pin_access: false,
  check_pin: false,
};



@State<AuthModel>({
  name: 'V04_APO_AUTH',
  defaults: _AuthDefault
})

@Injectable()
export class AuthState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Optional()
    @Inject(REQUEST) private request: Request
    ) {}

  ngxsAfterBootstrap(ctx?: StateContext<AuthModel>): void {
    if (isPlatformBrowser(this.platformId)) {
      this.store.dispatch(new AuthChangeAccessWithPin(false));
      this.store.dispatch(new AuthStatus());
    }
    if (isPlatformServer(this.platformId)) {}
  }

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx: StateContext<AuthModel>): void {}


  @Selector()
  static selectAccess( state: AuthModel ) {
    return state.access;
  }

  @Selector()
  static selectPinAccess( state: AuthModel ) {
    return state.pin_access;
  }

  @Selector()
  static selectCheckPin( state: AuthModel ) {
    return state.check_pin;
  }

  @Selector()
  static selectAuthToken( state: AuthModel ) {
    return state.token;
  }

  @Selector()
  static selectDefaultUser( state: AuthModel ) {
    return state.default_user;
  }

  @Action(AuthListing)
  async authListing(ctx: StateContext<AuthModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    if(_state_user.role == 'guest') {
      return;
    }
    let _listing = setInterval(() => {
      this.authService.listing().subscribe({
        next: _next => {

        },
        error: _error => {
          if(_error.status == 401) {
            clearInterval(_listing);
            ctx.dispatch(new AuthDisallowAccess());
          }
        }
      });
    }, 50000);
  }


  @Action(AuthStatus)
  authStatus(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.authService.status().subscribe({
        next: _next => {
          ctx.dispatch(new AuthUpdateToken(_next.token));
          ctx.dispatch(new AuthListing());
        },
        error: _error => {
          ctx.dispatch(new AuthDisallowAccess());
        }
      });
    }
    if(_state_user.role == 'guest' && _state.access) {
      this.store.dispatch(new AuthAllowAccess());
    }
  }



  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      access: true,
    });
  }


  @Action(AuthRemindPin)
  async authRemindPin(ctx: StateContext<AuthModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    this.authService.remind_pin().subscribe({
      next: _next => {},
      error: _error => {}
    });
  }
  @Action(AuthAllowPinAccess)
  authAllowPinAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      pin_access: true,
      default_user: undefined
    });
    this.router.navigate([this.router.url], { replaceUrl: true });
  }
  @Action(AuthDisallowPinAccess)
  authDisallowPinAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      pin_access: false,
    });
    this.store.dispatch(new SwitchUserToDefault());
    this.router.navigate([this.router.url], { replaceUrl: true });
  }

  @Action(AuthChangeAccessWithPin)
  authChangeAccessWithPin(ctx: StateContext<AuthModel>, payload) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      check_pin: payload.check,
    });
    this.router.navigate([], { queryParamsHandling: 'preserve', replaceUrl: true });
  }



  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    ctx.patchState({
      ..._state,
      access: false,
      pin_access: false,
      token: null,
      default_user: undefined
    });
    //ctx.dispatch(new ChangeMaxAction({energy: 10, life: 3}));
    ctx.dispatch(new SetSoundAction(true));
    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.router.navigate(['/'], { replaceUrl: true });
      this.authService.logout().subscribe(() => {
        localStorage.removeItem('V04_APO_DAY');
        //window.location.href = window.location.origin;
      });
    } else {
      localStorage.removeItem('V04_APO_DAY');
      this.router.navigate(['/'], { replaceUrl: true });
      //window.location.href = window.location.origin;
    }
  }

  @Action(AuthUpdateToken)
  authUpdateToken(ctx: StateContext<AuthModel>, payload) {
    ctx.patchState({
      token: payload.token,
    });

  }


  @Action(AuthSwitchUserToChild)
  AuthSwitchUserToChild(ctx: StateContext<AuthModel>, payload: { child: UserModel; }) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }

    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    ctx.patchState({
      ..._state,
      default_user: _state_user,
    });


    this.store.dispatch(new SwitchUserToChild(payload.child));
    this.authService.switch_user(payload.child._id).subscribe({next: _next => {}, error: _error => {}});

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  @Action(SwitchUserToDefault)
  public switchUserToDefault(ctx: StateContext<UserModel>) {
    const _default_auth_user = this.store.selectSnapshot(AuthState.selectDefaultUser);
    if(_default_auth_user) {
      this.authService.switch_user(_default_auth_user._id).subscribe({next: _next => {}, error: _error => {}});
    }
  }


}
