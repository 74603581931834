import {Inject, Injectable} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";
import {environment} from "../../environments/environment";
import {ReviewModel, WorkbookModel} from "../_stores/workbooks/_models/workbooks.model";
import {take} from "rxjs";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private _document: Document, private transloco: TranslocoService) { }

  updateTitle(title: string){
    this.title.setTitle(title + ' - ArgoPrep');
  }
  updateDescription(description: string){
    this.updateMetaTags([{name: 'description', content: this._trimStrings(description)}]);
  }
  updateOgMetaTags(OG: any){
    if(OG.update) {
      let _og_meta: MetaDefinition[] = Object.keys(OG).reduce((meta_definition, property) => {
        if (property !== "update") {
          if (property === "description") {
            meta_definition.push({ property: 'og:'+property, content: this._trimStrings(OG[property]) });
          }
          else {
            meta_definition.push({ property: 'og:'+property, content: OG[property] });
          }
        }
        return meta_definition;
      }, []);
      this.updateMetaTags(_og_meta);
    }

  }
  updateMetaTags(metaTags: MetaDefinition[]){
    metaTags.forEach(m=> this.meta.updateTag(m));
  }


  updateSchemaBreadcrumbs(_crumbs: any): void {
    let _schema_script = this._document.getElementById('bh-breadcrumb-schema');
    if (!_schema_script) {
      return;
    }
    if (_crumbs.breadcrumbs.length == 0) {
      _schema_script.innerHTML = '';
      return;
    }
    let _breadcrumbSchema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumb",
      "itemListElement": _crumbs.breadcrumbs.map((item, index) => {
        if (!item.translate) {
          return;
        }
        return {
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@id": environment.baseUrl + item.link+'/',
            "url": environment.baseUrl + item.link+'/',
            "name": item.translate
          }
        };
      })
    };
    _schema_script.innerHTML = JSON.stringify(_breadcrumbSchema);
  }
  updateSchemaOrganization() : void {
    let _organizationSchema =   {
      "@context": "https://schema.org",
      "@type": "Organization",
      "@id": "https://argoprep.com/",
      "name": "ArgoPrep",
      "url": "https://argoprep.com/",
      "logo": "https://argoprep.com/assets/images/logo_icon.svg",
      "foundingDate": "2022",
      "alternateName": "ArgoPrep",
      "description": "This is an innovative educational platform for preschoolers that offers thousands of developmental tasks and worksheets for children. Our resources help develop reading, writing, and math skills while simultaneously stimulating an interest in learning and science.",
      "location": {
        "@type": "VirtualLocation",
        "url": "https://argoprep.com/"
      },
      "sameAs": [
        "https://www.facebook.com/argoprep.com",
        "https://t.me/argoprep",
        "https://www.youtube.com/@argoprep"
      ],
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+1 (307) 312-0945",
          "contactType": "Support",
          "email": "info@argoprep.com",
          "areaServed": "EN",
          "availableLanguage": "en"
        }
      ]
    };
    let _schema_script = this._document.getElementById('bh-org-schema');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_organizationSchema);
  }
  updateSchemaWebsite() : void {
    let _websiteSchema =   {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "ArgoPrep",
      "alternateName":[
        "ArgoPrep",
        "AP",
        "Educational platform"
      ],
      "url": "https://argoprep.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "name": "Search",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://argoprep.com/search/?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    };
    let _schema_script = this._document.getElementById('bh-website-schema');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_websiteSchema);
  }
  updateSchemaCourse() : void {
    let _courseSchema =  {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": "Courses",
      "itemListElement": [

      ]
    };
    let _schema_script = this._document.getElementById('bh-course-schema');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_courseSchema);
  }
  async updateSchemaProduct(product: WorkbookModel, reviews: ReviewModel[]) : Promise<any> {
    const _product_translate = await this.transloco.selectTranslation('pages/workbook/en').pipe(take(1)).toPromise();
   if(_product_translate) {
     const _images = product.gallery.map(item => `https://argoprep.com/assets/images/workbooks/${item}`);
     _images.unshift(`https://argoprep.com/assets/images/workbooks/${product.thumbnail}`);
     let _productSchema =   {
       "@context": "https://schema.org/",
       "@type": ["Product", "Book"],
       "name": _product_translate[product.title.replace('pages.workbooks.', '')] + ' Preschool Activity Workbook',
       "image": _images,
       "description": _product_translate[product.description.replace('pages.workbooks.', '')].replace(/<[^>]*>/g, ''),
       "sku": "BHAWB-"+product.isbn,
       "gtin13": product.isbn,
       "brand": {
         "@type": "Brand",
         "name": "ArgoPrep"
       },
       "review": {
         "@type": "Review",
         "reviewRating": {
           "@type": "Rating",
           "ratingValue": 4,
           "bestRating": 5
         },
         "author": {
           "@type": "Person",
           "name": "Bistar Bexley"
         },
         "positiveNotes": {
           "@type": "ItemList",
           "itemListElement": [
             {
               "@type": "ListItem",
               "position": 1,
               "name": "Age-Appropriate Design"
             },
             {
               "@type": "ListItem",
               "position": 2,
               "name": "Engaging and Fun"
             },
             {
               "@type": "ListItem",
               "position": 3,
               "name": "Educational Illustrations"
             },
             {
               "@type": "ListItem",
               "position": 4,
               "name": "Developmental Benefits"
             },
             {
               "@type": "ListItem",
               "position": 5,
               "name": "Ideal for Homeschooling and Entertainment"
             }
           ]
         },
         "negativeNotes": {
           "@type": "ItemList",
           "itemListElement": [
             {
               "@type": "ListItem",
               "position": 1,
               "name": "Age Limitation"
             },
             {
               "@type": "ListItem",
               "position": 2,
               "name": "Physical Workbook Limitations"
             },
             {
               "@type": "ListItem",
               "position": 3,
               "name": "Cultural and Language Specificity"
             }
           ]
         },
         "reviewBody": reviews.length == 0? "": reviews[0].review,
         "publisher": "ArgoPrep"
       },
       "aggregateRating": {
         "@type": "AggregateRating",
         "ratingValue": 4.9,
         "reviewCount": reviews.length == 0? 38: reviews.length*3,
       },
       "offers": {
         "@type": "Offer",
         "url": `https://argoprep.com/workbooks/${product.category}/${product.slug}/`,
         "priceCurrency": "USD",
         "price": product.variations[0].costs.usd,
         "priceValidUntil": "2024-01-30",
         "itemCondition": "https://schema.org/NewCondition",
         "availability": "https://schema.org/InStock",
         "hasMerchantReturnPolicy": {
           "@type": "MerchantReturnPolicy",
           "applicableCountry": "UK",
           "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
           "merchantReturnDays": 60,
           "returnMethod": "https://schema.org/ReturnByMail",
           "returnFees": "https://schema.org/FreeReturn"
         }
       }
     };
     let _schema_script = this._document.getElementById('bh-product-schema');
     if(!_schema_script) { return;}
     _schema_script.innerHTML = JSON.stringify(_productSchema);
   }
  }

  async updateSchemaArticle(article: any) : Promise<any> {
      let _articleSchema =   {
        "@context": "https://schema.org/",
        "@type": "BlogPosting",
        "headline" : article.title,
        "author": [{
          "@type": "Person",
          "name": article.author?.name,
          "url": "https://argoprep.com/author/"+article.author?.slug+"/"
        }],
        "description": article.excerpt,
        "url": "https://argoprep.com/blog/"+article.slug+"/",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://argoprep.com/blog/"+article.slug+"/"
        },
        "datePublished" : new Date(article.date).toISOString(),
        "dateModified" : new Date(article.date).toISOString(),
        "publisher" : {
          "@type" : "Organization",
          "name": "ArgoPrep",
          "logo" : {
            "@type" : "ImageObject",
            "url" : "https://argoprep.com/assets/images/logo_icon.svg"
          }
        },
        "image" : {
          "@type" : "ImageObject",
          "url" : "https://argoprep.com"+article.thumbnail?.src
        },
        "articleSection": "Preschool",
        "isPartOf": {"@id": "https://argoprep.com/blog/"+article.slug+"/"},
        "inLanguage": "en-US"
      }

      let _schema_script = this._document.getElementById('bh-article-schema');
      if(!_schema_script) { return;}
      _schema_script.innerHTML = JSON.stringify(_articleSchema);
      this.updateSchemaArticleProduct(article).then();
  }
  async updateSchemaArticleProduct(article: any) : Promise<any> {
    let _articleSchema =   {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": article.title,
      "image" : {
        "@type" : "ImageObject",
        "url" : "https://argoprep.com"+article.thumbnail.src
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": ""+article.info.ratingValue,
        "bestRating": "5",
        "ratingCount": ""+(article.info.totalRating+article.info.index)
      }
    };
    let _schema_script = this._document.getElementById('bh-article-schema-product');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_articleSchema);
  }



  updateCanonicalURL(_crumb_url: string, _route_data: any): void {

    this.clearCanonicalURL();
    let _canonicalUrl = environment.baseUrl+((_crumb_url == '/')? '/' : _crumb_url);
    if(_route_data && _route_data.not_found) {
      _canonicalUrl = environment.baseUrl;
    }

    let _canonicalTag: HTMLLinkElement = this._document.createElement('link');
    _canonicalTag.setAttribute('rel', 'canonical');
    this._document.head.appendChild(_canonicalTag);
    _canonicalTag.setAttribute('href', _canonicalUrl);
    this.updateOgMetaTags({
      "update": true,
      "url": _canonicalUrl
    })
  }
  clearCanonicalURL() {
    const els = this._document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaOrganization(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-org-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaWebsite(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-website-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaCourse(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-course-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaBreadcrumb(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-breadcrumb-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaProduct(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-product-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaArticle(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-article-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
    const els2 = this._document.querySelectorAll('script[id=\'bh-article-schema-product\']');
    for (let i = 0, l = els2.length; i < l; i++) {
      const el2 = els2[i];
      el2.remove();
    }
  }



  updateNoIndexUrl(_route_data: any): void {
    this.clearNoIndexUrl();
    if(_route_data && _route_data.noindex) {
      this.updateMetaTags([{name: 'robots', content: "noindex, nofollow"}]);
    }
  }
  clearNoIndexUrl() {
    const els = this._document.querySelectorAll('meta[name=\'robots\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }



  _trimStrings(text: string) {
    if(!text) {
      return  '';
    }
    const maxLength = 160;
    const withoutHtmlTags = text.replace(/<\/?[^>]+(>|$)/g, '');
    const trimmedText = withoutHtmlTags.trim();

    if (trimmedText.length <= maxLength) {
      return trimmedText;
    }

    const words = trimmedText.split(' ');
    let truncatedText = '';
    let currentLength = 0;

    for (const word of words) {
      if (currentLength + word.length + 1 <= maxLength) {
        truncatedText += word + ' ';
        currentLength += word.length + 1;
      } else {
        break;
      }
    }

    return truncatedText.trim();
  }

  initSchema(id?: string) {
    const schemaTypes = [
      { id: "bh-breadcrumb-schema", type: "application/ld+json" },
      { id: "bh-org-schema", type: "application/ld+json" },
      { id: "bh-website-schema", type: "application/ld+json" },
      { id: "bh-product-schema", type: "application/ld+json" },
      { id: "bh-course-schema", type: "application/ld+json" },
      { id: "bh-article-schema", type: "application/ld+json" },
      { id: "bh-article-schema-product", type: "application/ld+json" }
    ];

    const bodyElement = this._document.getElementsByTagName('body')[0];

    if(id === 'bh-article-schema') {
      this.initSchema('bh-article-schema-product');
    }

    if(id) {
      let _schema_script = this._document.createElement('script');
      _schema_script.type = "application/ld+json";
      _schema_script.id = id;
      const _schema_script_element = this._document.getElementById(id);
      if(!_schema_script_element) {
        bodyElement.appendChild(_schema_script);
      }
      return;
    }


    schemaTypes.forEach(schemaType => {
      let _schema_script = this._document.createElement('script');
      _schema_script.type = schemaType.type;
      _schema_script.id = schemaType.id;
      const _schema_script_element = this._document.getElementById(schemaType.id);
      if(!_schema_script_element) {
        bodyElement.appendChild(_schema_script);
      }
    });
  }

}
