import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {UserModel} from "../_models/user.model";

import {ChangeLanguageAction, ChangeSoundAction, SetSoundAction,} from "../../settings/_actions/settings.actions";

import {UserService} from "../_services/user.service";
import {Router} from "@angular/router";
import {
  ChangeUserGradeAction,
  CountDownEnergy,
  CountDownLife,
  CountDownSpendStar,
  CountUpEnergy,
  CountUpLife,
  CountUpPoints,
  CountUpSpendStar,
  InitSSE,
  initUser,
  RenewUser,
  SwitchUserToChild,
  SwitchUserToDefault,
  UpdateUpEnergy,
  UpdateUpLife,
  updateUser,
  updateUserAddress
} from "../_actions/user.actions";
import {TranslocoService} from "@ngneat/transloco";
import {
  AuthAllowAccess,
  AuthDisallowAccess,
  AuthListing,
  AuthNewToken,
  AuthUpdateToken
} from "../../auth/_actions/auth.actions";
import {StartTimer, StopTimer} from "../../timer/_actions/timer.actions";
import {SettingsState} from "../../settings/_state/settings.state";
import {TipsOpenAction} from "../../tips/_actions/tips.actions";
import {AuthState} from "../../auth/_state/auth.state";
import {ModalCloseAction} from "../../modal/_actions/modal.actions";
import {RouterState} from "@ngxs/router-plugin";
import {
  ClearPracticesCategory,
  ClearPracticesPlans,
  LoadPracticesCategory,
  LoadPracticesPlans
} from "../../practice/_actions/practice.actions";
import {ClearProductsFromCart} from "../../cart/_actions/cart.actions";
import {isPlatformBrowser} from "@angular/common";
import {WorksheetsState} from "../../worksheets/_state/worksheets.state";

export const _StatesDefault = [
  { "code": "AL", "name": "Alabama" },
  { "code": "AK", "name": "Alaska" },
  { "code": "AZ", "name": "Arizona" },
  { "code": "AR", "name": "Arkansas" },
  { "code": "CA", "name": "California" },
  { "code": "CO", "name": "Colorado" },
  { "code": "CT", "name": "Connecticut" },
  { "code": "DE", "name": "Delaware" },
  { "code": "DC", "name": "District Of Columbia" },
  { "code": "FL", "name": "Florida" },
  { "code": "GA", "name": "Georgia" },
  { "code": "HI", "name": "Hawaii" },
  { "code": "ID", "name": "Idaho" },
  { "code": "IL", "name": "Illinois" },
  { "code": "IN", "name": "Indiana" },
  { "code": "IA", "name": "Iowa" },
  { "code": "KS", "name": "Kansas" },
  { "code": "KY", "name": "Kentucky" },
  { "code": "LA", "name": "Louisiana" },
  { "code": "ME", "name": "Maine" },
  { "code": "MD", "name": "Maryland" },
  { "code": "MA", "name": "Massachusetts" },
  { "code": "MI", "name": "Michigan" },
  { "code": "MN", "name": "Minnesota" },
  { "code": "MS", "name": "Mississippi" },
  { "code": "MO", "name": "Missouri" },
  { "code": "MT", "name": "Montana" },
  { "code": "NE", "name": "Nebraska" },
  { "code": "NV", "name": "Nevada" },
  { "code": "NH", "name": "New Hampshire" },
  { "code": "NJ", "name": "New Jersey" },
  { "code": "NM", "name": "New Mexico" },
  { "code": "NY", "name": "New York" },
  { "code": "NC", "name": "North Carolina" },
  { "code": "ND", "name": "North Dakota" },
  { "code": "OH", "name": "Ohio" },
  { "code": "OK", "name": "Oklahoma" },
  { "code": "OR", "name": "Oregon" },
  { "code": "PA", "name": "Pennsylvania" },
  { "code": "RI", "name": "Rhode Island" },
  { "code": "SC", "name": "South Carolina" },
  { "code": "SD", "name": "South Dakota" },
  { "code": "TN", "name": "Tennessee" },
  { "code": "TX", "name": "Texas" },
  { "code": "UT", "name": "Utah" },
  { "code": "VT", "name": "Vermont" },
  { "code": "VA", "name": "Virginia" },
  { "code": "WA", "name": "Washington" },
  { "code": "WV", "name": "West Virginia" },
  { "code": "WI", "name": "Wisconsin" },
  { "code": "WY", "name": "Wyoming" },
  { "code": "AA", "name": "Armed Forces (AA)" },
  { "code": "AE", "name": "Armed Forces (AE)" },
  { "code": "AP", "name": "Armed Forces (AP)" }
];

export const _UserDefault: UserModel = {
  _id: null,
  email: null,
  first_name: "",
  last_name: "",
  display_name: "",
  avatar: "",
  role: "guest",
  friends: [],
  bin: null,
  score: {
    energy: 0,
    life: 0,
    point: 0,
    star: {
      all: 0,
      spend: 0
    }
    },
  settings: {
    pin:null,
    workbooks_after_register: true,
    children_count: 1,
    education_week: 0,
    education_day: 1,
    sound: true,
    style: 'light',
    language: 'en',
    grade: null,
    speed: {
      energy: 1,
      life: 1
    }
  },
  billing: {
    first_name: "",
    last_name: "",
    country: "United States (US)",
    city: "",
    state: "",
    address: "",
    postcode: null,
    phone: null

  },
  relationship: "",
  children: [],
  ebooks: [],
  workbooks: [],
  awards: [],
  downloads: [],
};

@State<UserModel>({
  name: 'V04_APO_USER',
  defaults: _UserDefault,
})
@Injectable()
export class UserState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  isBrowser: boolean;
  sse_signals: any;


  constructor(private store: Store, private userService: UserService, private router: Router, private transloco: TranslocoService,  @Inject(PLATFORM_ID) platformId: Object,) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngxsOnInit(ctx?: StateContext<any>): any {}

  ngxsOnChanges(change: NgxsSimpleChange<UserModel>): void {}

  ngxsAfterBootstrap(ctx: StateContext<UserModel>) {

  }


  @Selector()
  static selectSettings(state: UserModel) {
    return state.settings;
  }

  @Selector([WorksheetsState.selectWorksheets])
  static selectUserDownloadedWorksheets(
      state: UserModel, // Текущее состояние UserState
      worksheets: { _id: string; [key: string]: any }[] // Объекты из WorksheetsState
  ) {
    // Фильтрация объектов из WorksheetsState по _id из state.downloads
    return worksheets.filter(worksheet => state.downloads.includes(worksheet._id));
  }

  @Selector()
  static selectUser(state: UserModel) {
    return state;
  }
  @Selector()
  static selectGrade(state: UserModel) {
    return state.settings.grade;
  }
  @Selector()
  static selectRelationship(state: UserModel) {
    return state.relationship;
  }
  @Selector()
  static selectEmail(state: UserModel) {
    return state.email;
  }

  @Selector()
  static selectUserEnergy(state: UserModel){
    return state.score.energy;
  }

  @Selector()
  static selectDefaultStates(state: UserModel) {
    return _StatesDefault;
  }

  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<UserModel>) {
    const _state = ctx.getState();
    let _user = {
      ..._UserDefault,
      settings: {
        ..._UserDefault.settings,
        sound: _state.settings.sound,
        language: _state.settings.language
      }
    };
    ctx.setState(_user);
    if(this.sse_signals) {
      this.sse_signals.close();
      this.sse_signals = undefined;
    }
  }

  @Action(AuthNewToken)
  async authNewToken(ctx: StateContext<UserModel>) {
    ctx.dispatch(new InitSSE());
    const _user = await this.userService.get().toPromise();
    if(_user) {
      ctx.setState(_user);
      ctx.dispatch(new AuthAllowAccess());
      ctx.dispatch(new AuthListing());
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
      ctx.dispatch(new SetSoundAction(_user.settings.sound));
      ctx.dispatch(new ClearPracticesPlans());
      ctx.dispatch(new ClearPracticesCategory());
      ctx.dispatch(new LoadPracticesPlans());
      ctx.dispatch(new LoadPracticesCategory());
      ctx.dispatch(new ModalCloseAction(true));
      const _url = this.store.selectSnapshot(RouterState.url);
      if(_url == '/auth/') {
        this.router.navigate(['/dashboard/']).then();
      } else {
        //this.router.navigate(['/dashboard/']).then();
       this.router.navigate([], { replaceUrl: true });
      }
    }
  }


  @Action(AuthUpdateToken)
  async authUpdateToken(ctx: StateContext<UserModel>) {
    ctx.dispatch(new InitSSE());
    const _user = await this.userService.get().toPromise();
    if(_user) {
      ctx.setState(_user);
      ctx.dispatch(new AuthAllowAccess());
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
      ctx.dispatch(new SetSoundAction(_user.settings.sound));
      this.router.navigate([this.router.url], { replaceUrl: true });
      ctx.dispatch(new LoadPracticesCategory());
      ctx.dispatch(new LoadPracticesPlans());
    }
  }
  @Action(ClearProductsFromCart)
  async clearProductsFromCart(ctx: StateContext<UserModel>) {
    const _user = await this.userService.get().toPromise();
    if(_user) {
      ctx.setState(_user);
    }
  }
  @Action(RenewUser)
  async renewUser(ctx: StateContext<UserModel>) {
    const _user = await this.userService.get().toPromise();
    if(_user) {
      ctx.setState(_user);
    }
  }

  @Action(InitSSE)
  async initSSESignals(ctx: StateContext<UserModel>) {

    if(this.sse_signals) {
      return;
    }
    this.sse_signals = this.userService.sseMessages();
    if (!this.sse_signals) {
      return;
    }

    this.sse_signals.onopen = () => {

    };
    this.sse_signals.onmessage = ({ data }) => {
      let _message = JSON.parse(data);
      if(_message.type) {
        if(_message.type == '[Tips] TipsOpenAction' && _message.data?.options?.type == 'award') {
          setTimeout(() => {
            this.store.dispatch(Object.assign(Object.assign({}, _message.data), { type: _message.type }));
          }, 2000);
        } else {
          this.store.dispatch(Object.assign(Object.assign({}, _message.data), { type: _message.type }));
        }

      }
    };
    this.sse_signals.onerror = () => {

    };
  }



  @Action(ChangeSoundAction)
  public changeSoundAction(ctx: StateContext<UserModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        sound: !state.settings.sound
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateSound(!state.settings.sound).subscribe();
    }
  }
  @Action(ChangeLanguageAction)
  public changeLanguageAction(ctx: StateContext<UserModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        language: payload.language,
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      //this.userService.updateLanguage(payload.language).subscribe();
    }
  }
  @Action(ChangeUserGradeAction)
  public changeGradeAction(ctx: StateContext<UserModel>, _grade) {
    const state = ctx.getState();
    if(JSON.stringify(state.settings.grade) === JSON.stringify(_grade.data)) {
      return;
    }
    else {
      ctx.dispatch(new ClearPracticesPlans());
      ctx.dispatch(new ClearPracticesCategory());

      ctx.patchState({
        ...state,
        settings: {
          ...state.settings,
          grade: _grade.data
        }
      });
      if (state.role == 'user' || state.role == 'premium'){
        this.userService.updateGrade(_grade.data._id).subscribe({
          next: _next => {
            ctx.dispatch(new LoadPracticesPlans());
            ctx.dispatch(new LoadPracticesCategory());
          },
          error: _error => {
          }
        });
      }
    }
  }





  @Action(initUser)
  public initUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    ctx.patchState({
      ..._state,
      first_name: _user.first_name,
      last_name: _user.last_name,
      display_name: _user.first_name + ' ' + _user.last_name,
      avatar: _user.avatar,
      email: _user.email,
      billing: {
        ..._state.billing,
        first_name: _user.first_name,
        last_name: _user.last_name,
      }
    });
  }
  @Action(updateUser)
  public updateUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    const _user_state = {..._userData.user};
    if(_user_state.password) {
      delete _user_state.password;
    }

    if(_state.settings.language != _user.settings.language) {
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
    }

    ctx.setState(_user_state);
    if (_state.role == 'user' || _state.role == 'premium'){
      let _update_user_fields = ['avatar', 'billing', 'display_name', 'email', 'first_name', 'last_name', 'password', 'relationship', 'children' , 'settings', 'ebooks', 'workbooks'];
      const updated_user_data = (_user, _update_user_fields) =>
        _update_user_fields.reduce((acc, key) => {
          if (_user.hasOwnProperty(key)) {
            acc[key] = _user[key];
          }
          return acc;
        }, {});
      const updatedUserData = updated_user_data(_user, _update_user_fields);
      this.userService.updateAccountData(updatedUserData).subscribe({
        next: _next => {
          ctx.dispatch(new RenewUser());
        },
        error: _error => {
        }
      });
    }
  }

  @Action(updateUserAddress)
  public updateUserAddress(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    const _user_state = {..._userData.user};
    if (_state.role == 'user' || _state.role == 'premium'){
      let _update_user_fields = ['billing', 'display_name', 'email', 'first_name', 'last_name'];
      const updated_user_data = (_user, _update_user_fields) =>
          _update_user_fields.reduce((acc, key) => {
            if (_user.hasOwnProperty(key)) {
              acc[key] = _user[key];
            }
            return acc;
          }, {});
      const updatedUserData = updated_user_data(_user, _update_user_fields);
      this.userService.updateAccountData(updatedUserData).subscribe({
        next: _next => {
          ctx.dispatch(new RenewUser());
        },
        error: _error => {
        }
      });
    }
  }
  @Action(SwitchUserToChild)
  public switchUserToChild(ctx: StateContext<UserModel>, _userData: { child: UserModel; }) {
    const _state = ctx.getState();
    const _child = _userData.child;
    ctx.setState(_child);
  }

  @Action(SwitchUserToDefault)
  public switchUserToDefault(ctx: StateContext<UserModel>) {
    const _default_auth_user = this.store.selectSnapshot(AuthState.selectDefaultUser);
    const _state = ctx.getState();
    if(_default_auth_user) {
      ctx.setState(_default_auth_user);
    }
  }


  @Action(CountDownEnergy)
  public countDownEnergy(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const _state = ctx.getState();
    const _energy = (_state.score.energy > 0) ? _state.score.energy - 1 : 0;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy: _energy,
      }
    });
    ctx.dispatch(new StartTimer('energy'));

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(-1).subscribe();
    }


  }
  @Action(CountUpEnergy)
  public countUpEnergy(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.energy == state_settings.max.energy) {return;}

    if (_state.score.energy + 1  == state_settings.max.energy) {
      ctx.dispatch(new StopTimer('energy'));
    }

    const _energy = _state.score.energy + 1;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy:  _energy,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(1).subscribe();
    }
  }
  @Action(UpdateUpEnergy)
  public updateUpEnergy(ctx: StateContext<UserModel>, _energyData) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.energy + 1  == state_settings.max.energy) {

    }

    let _energy = _state.score.energy + _energyData.count;

    if(_energyData.from_timer && _energy > state_settings.max.energy) {
      _energy = state_settings.max.energy;
    }

    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy:  _energy,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(_energyData.count).subscribe();
    }
  }



  @Action(CountDownLife)
  public countDownLife(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const _state = ctx.getState();

    const _life = (_state.score.life > 0) ? _state.score.life - 1 : 0;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life: _life,
      }
    });


    ctx.dispatch(new StartTimer('life'));

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(-1).subscribe();
    }

  }
  @Action(CountUpLife)
  public countUpLife(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.life == state_settings.max.life) {return;}

    if (_state.score.life + 1  == state_settings.max.life) {

      ctx.dispatch(new StopTimer('life'));
    }

    const _life = _state.score.life + 1;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life:  _life,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(1).subscribe();
    }
  }
  @Action(UpdateUpLife)
  public updateUpLife(ctx: StateContext<UserModel>, _lifeData) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.life + 1  == state_settings.max.life) {

    }

    let _life = _state.score.life + _lifeData.count;

    if(_lifeData.from_timer && _life > state_settings.max.life) {
      _life = state_settings.max.life;
    }

    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life:  _life,
      }
    });

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(_lifeData.count).subscribe();
    }

  }



  @Action(CountDownSpendStar)
  public countDownSpendStar(ctx: StateContext<UserModel>, CountDownSpendStar: CountDownSpendStar) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        star: {
          ..._state.score.star,
          spend: _state.score.star.spend - CountDownSpendStar.count
        },
      }
    });


    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateSpendStar(CountDownSpendStar.count*-1).subscribe();
    }
  }

  @Action(CountUpSpendStar)
  public countUpSpendStar(ctx: StateContext<UserModel>, CountUpSpendStar: CountUpSpendStar) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        star: {

          ..._state.score.star,
          spend: _state.score.star.spend + CountUpSpendStar.count,
          all: _state.score.star.all + CountUpSpendStar.count
        },
      }
    });



    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateSpendStar(CountUpSpendStar.count).subscribe();
    }

  }


  @Action(CountUpPoints)
  public countUpPoints(ctx: StateContext<UserModel>, CountUpPoints: CountUpPoints) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        point: _state.score.point + CountUpPoints.count,
      }
    });


    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateSpendPoints(CountUpPoints.count).subscribe();
    }
    ctx.dispatch(new TipsOpenAction({type: 'points', count:CountUpPoints.count}));

  }
}
