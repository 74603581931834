import {
    Action,
    createSelector,
    NgxsAfterBootstrap,
    NgxsOnChanges,
    NgxsOnInit,
    NgxsSimpleChange,
    Selector,
    State,
    StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {BlogModel} from "../_models/blog.model";
import {InitBlogPosts} from "../_actions/blog.actions";
import {BlogService} from "../_services/blog.service";


export const _BlogDefault: BlogModel = {
  posts: [],
};

@State<BlogModel>({
  name: 'V04_APO_BLOG',
  defaults: _BlogDefault,
})
@Injectable()
export class BlogState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private blogService: BlogService) {

  }
  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<BlogModel>): void {}
  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    ctx.dispatch(new InitBlogPosts());
  }

  @Selector()
  static selectPosts( state: BlogModel) {
    return state.posts;
  }

  static selectPostBySlug( _slug: string ) {
    return createSelector([ BlogState.selectPosts ], ( _posts: any[]) => {
      return _posts.find(x => x.slug === _slug);
    });
  }


  @Action(InitBlogPosts)
  async initBlogPosts(ctx: StateContext<BlogModel>, _data) {
    const _state = ctx.getState();
    let _posts = await this.blogService.getPosts();
    let _posts_with_slug = _posts.reduce((acc, obj) => {
      if (obj.url) {
        const urlParts = obj.url.split('/');
        const slug = urlParts[urlParts.length - 2];
        acc.push({ ...obj, slug });
      } else {
        acc.push({ ...obj });
      }
      return acc;
    }, []);
      ctx.patchState({
      ..._state,
      posts: _posts_with_slug
    });
  }

}
